.App {
  text-align: center;

  .App-header {
    background-color: #1f2533;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;

    .main {
      #topLogo {
        width: 150px;
        height: auto;
      }

      h1 {
        font-size: 2.5em;
        margin: 20px 0;
      }

      p {
        font-size: 1.2em;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;

      input[type="text"],
      input[type="email"] {
        margin: 0 0 0.5em 0;
        padding: 10px;
        width: 325px;
        max-width: 90 vw;
        border: none;
        // border-radius: 20px;
        box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
      }

      input[type="submit"] {
        padding: 0.66em 2em;
        background-color: #61dafb;
        border: none;
        color: black;
        margin-top: 0.2em;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;
        // border-radius: 20px;
        box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);

        &:hover {
          background-color: #52cdef;
          transform: scale(1.05);
        }
      }

      #message {
        margin-top: 20px;
      }
    }
  }
}